<template>
  <div class="home">

    <form @submit.prevent="submit">
      <img srcset="../assets/people@2x.png 2x ../assets/people@3x.png 3x" src="../assets/people.png"/>
      <br />
      <br />

      <div>
        <label>Entrez votre code livreur</label>
        <input class="control" v-model="codeLivreur"  type="number"/>
        <button type="submit">Valider</button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'Login',
  components: {
    
  },
  data() {
    return {
      codeLivreur: null
    }
  },
  mounted() {
    let codeLivreur = window.localStorage.getItem('codeLivreur')
    if (codeLivreur) {
      this.$router.push({ path: 'Home' })
    }
  },
  methods: {
    async submit() {
      await axios.get('https://tms.pre-prod.pro/livreur/auth/'+this.codeLivreur).then(response => {
          console.log(response);
          window.localStorage.setItem('codeLivreur', this.codeLivreur)
          this.$router.push({ path: 'Home' })
      }).catch( error => {
        if (error.response) {
          alert("Code livreur non valide")
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          alert("Code livreur non valide")
        } else {
          alert(error.message)
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 20px;

  form {
    max-width: 360px;
    margin: auto;
    padding-top: 80px;
    label {
      color: #FFF;
      display: block;
      margin-bottom: 20px;
    }

    input {
      display: block;
      margin: auto;
      margin-bottom: 30px;
    }

    button {
      
    }
  }
}
</style>
