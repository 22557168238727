import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import('../views/Scan.vue')
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => import('../views/Sign.vue')
  },
  {
    path: '/end',
    name: 'End',
    component: () => import('../views/End.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
