<template>
  <div id="app">
    <header>
      <router-link :to="backLink" v-if="backLink">
        <img src="./assets/back.svg"/>
      </router-link>
      <span v-if="!backLink">&nbsp;</span>
      <img srcset="./assets/logo@2x.png 2x ./assets/logo@3x.png 3x" src="./assets/logo.png"/>
    </header>
    <main>
      <router-view/>
    </main>
    <footer>
      <div>
        <div>
          <a href="tel:0148970870">
            <img srcset="./assets/phone@2x.png 2x ./assets/phone@3x.png 3x" src="./assets/phone.png"/>
            <span>Appeler TMS</span>
          </a>
        </div>

        <div>
          <a href="#" v-if="codeLivreur" @click.prevent="logout">
            <img srcset="./assets/logout@2x.png 2x ./assets/logout@3x.png 3x" src="./assets/logout.png"/>
            <span>Déconnexion</span>
          </a>
          <span v-if="!codeLivreur">
            <label>{{ version }}</label>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const _config  = require('../package.json')
export default {
  data() {
    return {
      codeLivreur: null,
      version: null,
      backLink: null
    }
  },
  mounted() {
    this.codeLivreur = window.localStorage.getItem('codeLivreur')
    this.version = _config.version
    this.setBackLink()
  },
  methods: {
    logout() {
      window.localStorage.removeItem('codeLivreur')
      this.$router.push({ path: '/' })
    },
    setBackLink() {
      if (this.$route.path === '/scan') {
        this.backLink = '/home'
        return;
      }

      if (this.$route.path === '/sign') {
        this.backLink = '/scan'
        return;
      }

      this.backLink = null
    }
  },
  watch: {
    $route() {
      this.codeLivreur = window.localStorage.getItem('codeLivreur')
      this.setBackLink()
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #004c71;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  main {
    height: calc(100vh - 186px);
  }

  footer {
    height: 100px;
    > div {
      display: flex;
      justify-content: space-between;
      height: 70px;
      align-items: center;
      width: 90%;
      margin: auto;

      > div {
        display: flex;
        flex-direction: column;

        span {
          display: block;
          color: #FFF;

          label {
            font-size: 12px;
          }
        }

        a {
          color: #FFF;
          text-decoration: none;
        }
      }
    }
  }
}

input {
  -webkit-appearance : none;
}

input.control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FFF;
  max-width: 360px;
  width: 100%;
  color: #FFF;
  height: 30px;
  -webkit-appearance: none;
}


button {
  width: 145px;
  height: 60px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  background-color: #40b187;
  border: none;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}
</style>
